<template>
    <el-dialog
        :visible="dialogVisible"
        @close="handleClose"
        width="800px">
        <div style="color: #E6A23C">建议图片大小2M以内</div>
        <div style="color: #E6A23C">上传图片即可，无须保存。如需修改图片，点击相应图片上传即可覆盖</div>
        <div v-for="item in member_works"
            :key="item.id">
            <template v-if="checkedStyle.includes(item.styleID)">
                <!-- 户外写真 -->
                <div class="center-member-dialog-title">{{item.name}}</div>
                <center-member-dialog-list
                    :images-list="item.images || []"
                    :style-id="item.styleID">
                </center-member-dialog-list>
            </template>
        </div>
    </el-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
    data() {
        return {
        }
    },
    props: {
        "checkedStyle": Array
    },
    computed: mapState({
        dialogVisible: state => state.dialogVisible,
        member_works: state => state.user.member_works
    }),
    components: {
        centerMemberDialogList: () => import("@/components/centerMemberDialogList")
    },
    methods: {
        ...mapMutations([
            "changeDialogVisible",
            "setMemberWorks"
        ]),
        handleClose(){
            this.changeDialogVisible(false);
            this.setMemberWorks([]);
        }
    },
}
</script>

<style scoped>
.center-member-dialog-title{
    margin: 15px 0;
    color: #8d9afc;
}
</style>